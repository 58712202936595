/* Exo */
@font-face {
  font-family: Exo;
  font-style: normal;
  font-weight: 800;
  src: url("../../public/fonts/Exo/exo-v19-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/Exo/exo-v19-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../public/fonts/Exo/exo-v19-latin-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/Exo/exo-v19-latin-800.woff") format("woff"),
    /* Modern Browsers */ url("../../public/fonts/Exo/exo-v19-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/Exo/exo-v19-latin-800.svg#Exo") format("svg"); /* Legacy iOS */

  font-display: swap;
}

/* MerriWeather */
@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-weight: 700;
  src: url("../../public/fonts/Merriweather/merriweather-v30-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/Merriweather/merriweather-v30-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/Merriweather/merriweather-v30-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/Merriweather/merriweather-v30-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/Merriweather/merriweather-v30-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/Merriweather/merriweather-v30-latin-700italic.svg#Merriweather")
      format("svg"); /* Legacy iOS */

  font-display: swap;
}

/* Cambay */
@font-face {
  font-family: Cambay;
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/Cambay/cambay-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../public/fonts/Cambay/cambay-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../public/fonts/Cambay/cambay-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../public/fonts/Cambay/cambay-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../public/fonts/Cambay/cambay-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../public/fonts/Cambay/cambay-v12-latin-regular.svg#Cambay")
      format("svg"); /* Legacy iOS */

  font-display: swap;
}

html,
body {
  overflow: hidden;
  height: 100%;
}

h1 {
  font-family: Exo, sans-serif;
  font-size: 5vw;
}

h2 {
  font-family: Merriweather, serif;
  font-size: 5vw;
}

:root {
  font-family: Cambay, sans-serif;
  font-size: 2vw;
}

* {
  margin: 0;
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 10vw;
  }

  h2 {
    font-size: 10vw;
  }

  :root {
    font-size: 4vw;
  }
}
