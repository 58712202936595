.footer {
  /* background: linear-gradient(
    to right,
    #e1ffde,
    #aae2cc,
    #74c4c1,
    #43a5b8,
    #2084ac,
    #286197
  ); */
  background-color: #c5c5c5;
  margin-top: auto;
  height: 10vh;
}

/* stylelint-disable-next-line selector-class-pattern */
.footer__text {
  font-family: Merriweather, serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .footer {
    background-color: #4c5b61;
    color: #000;
  }
}
