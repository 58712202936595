.introduction {
  height: 100vh;
  background-color: #c5c5c5;
  width: 100%;
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .introduction {
    background-color: #4c5b61;
    color: #f0f0f0;
  }
}
