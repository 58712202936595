.experiences {
  height: 100vh;
  background-color: #c5c5c5;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
}

/* stylelint-disable-next-line selector-class-pattern */
.experiences__description {
  font-size: 1.5vw;
}

@media screen and (max-width: 700px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .experiences__description {
    font-size: 3vw;
  }
}

@media (prefers-color-scheme: dark) {
  .experiences {
    background-color: #4c5b61;
    color: #f0f0f0;
  }
}
