.title {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* stylelint-disable-next-line selector-class-pattern */
.title__text {
  text-align: center;
  margin: auto;
}
