.project-section {
  height: 100vh;
  background-color: #c5c5c5;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (prefers-color-scheme: dark) {
  .project-section {
    background-color: #4c5b61;
  }
}
