.home {
  flex-flow: column nowrap;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.home div {
  -ms-scroll-snap-align: center;
  scroll-snap-align: center;
}
