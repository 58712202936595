.projects {
  height: 100vh;
  background-color: #c5c5c5;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
}

.projects h2 {
  color: white;
}

@media (prefers-color-scheme: dark) {
  .projects {
    background-color: #4c5b61;
    color: #f0f0f0;
  }
}
