.skill-card {
  margin: 0 5px;
}

/* stylelint-disable-next-line selector-class-pattern */
.skill-card__image {
  width: 10vh;
  height: 10vh;
  border-radius: 20px;
  transition-duration: 250ms;
}

/* stylelint-disable-next-line selector-class-pattern */
.skill-card__image:hover {
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
  transform: scale(1.1);
}

@media screen and (max-width: 700px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .skill-card__image {
    width: 5vh;
    height: 5vh;
    border-radius: 10px;
  }
}
