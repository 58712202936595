.skills {
  height: 90vh;
  background-color: #c5c5c5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills h2 {
  text-align: center;
}

/* stylelint-disable-next-line selector-class-pattern */
.skills__type {
  text-align: center;
  font-weight: 700;
}

/* stylelint-disable-next-line selector-class-pattern */
.skills__collection {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {
  .skills {
    background-color: #4c5b61;
    color: #f0f0f0;
  }
}
