.card {
  width: 80%;
  height: 80%;
  background-color: #949b96;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__image {
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
  border-radius: 10px;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__paragraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__title {
  font-weight: 700;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__title:hover {
  text-decoration: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__text {
  font-size: 1.5vw;
}

a {
  all: unset;
  text-decoration: underline;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__github::after {
  content: "";
  border: solid black;
  border-width: 0 5px 5px 0;
  padding: 5px;
  display: inline-block;
  transform: rotate(-45deg);
  margin-left: 5px;
  transition-duration: 250ms;
}

/* stylelint-disable-next-line selector-class-pattern */
.card__github:hover::after {
  transform: scale(1.3) rotate(-45deg);
}

a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .card {
    flex-direction: column;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .card__image {
    width: 100%;
    height: 80%;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .card__title {
    font-size: 4vw;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .card__text {
    font-size: 3vw;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .card__github::after {
    border-width: 0 2px 2px 0;
    padding: 2px;
  }
}
